// extracted by mini-css-extract-plugin
export var aboveCenter = "Popover__aboveCenter__dIi16";
export var aboveLeftEdge = "Popover__aboveLeftEdge__fnv70";
export var aboveRightEdge = "Popover__aboveRightEdge__QcHi8";
export var belowCenter = "Popover__belowCenter__RrwqV";
export var belowLeftEdge = "Popover__belowLeftEdge__UalwN";
export var belowRightEdge = "Popover__belowRightEdge__sqrYh";
export var body = "Popover__body__kvp6W";
export var column = "Popover__column__GEXH3";
export var container = "Popover__container__adRKa";
export var flex = "Popover__flex__sAH_J";
export var flexColumn = "Popover__flexColumn__t5HAu";
export var gap1 = "Popover__gap1__kSJil";
export var gap2 = "Popover__gap2__tWYiK";
export var gap3 = "Popover__gap3___4nkF";
export var gap4 = "Popover__gap4__blopU";
export var gap5 = "Popover__gap5__XSHcf";
export var onLeft = "Popover__onLeft__NRX36";
export var onRight = "Popover__onRight__Juy3z";
export var row = "Popover__row__PQ76f";